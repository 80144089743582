// import axios from "axios";

/*export default axios.create({
    baseURL: "http://localhost:8080/api",
    headers: {
        "Content-type": "application/json"
    }
});*/

class apiService {
        getApiUrl() {
                return "http://localhost:8080/api"
        }
        device() {
                return this.getApiUrl()+"/device"
        }

        sensor() {
                return this.getApiUrl()+"/sensor"
        }
}

export default new apiService();