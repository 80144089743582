import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import AppNavbar from '../layout/AppNavbar';
import { Link } from 'react-router-dom';
import apiService from '../ApiConfig';

class DeviceList extends Component {
    routePath = "devices";

    constructor(props) {
        super(props);
        this.state = {devices: []};
        this.remove = this.remove.bind(this);
    }

    componentDidMount() {
        fetch(apiService.device())
            .then(response => response.json())
            .then(data => this.setState({devices: data}));
    }

    async remove(id) {
        await fetch(apiService.device()+`/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(() => {
            let updatedDevices = [...this.state.devices].filter(i => i.id !== id);
            this.setState({devices: updatedDevices});
        });
    }

    render() {
        const {devices} = this.state;

        const deviceList = devices.map(device => {
            // const deviceSensorsSorted = device.deviceSensors.sort((a, b) => (a.name < b.name)  ? -1 : 1);
            const deviceSensorsSorted = device.deviceSensors.sort((a, b) => a.sensor.sensorType.measureQuantity.id - b.sensor.sensorType.measureQuantity.id || a.object.id - b.object.id || a.name - b.name);
            const deviceSensorList = deviceSensorsSorted.map(deviceSensor => {
                return <tr key={deviceSensor.id}>
                    <td style={{whiteSpace: 'nowrap'}}>{deviceSensor.name}</td>
                    <td>{deviceSensor.place && deviceSensor.place.name}</td>
                    <td>{deviceSensor.pipeLine && deviceSensor.pipeLine.name}</td>
                    <td>{deviceSensor.object && deviceSensor.object.name}</td>
                    <td>{deviceSensor.sensor && deviceSensor.sensor.sensorType && deviceSensor.sensor.sensorType.measureQuantity.name}</td>
                    <td>{deviceSensor.sensor && deviceSensor.sensor.sensorType && deviceSensor.sensor.sensorType.measureQuantity.unit.name}</td>
                    <td>{deviceSensor.sensor && deviceSensor.sensor.name}</td>
                    <td>{deviceSensor.sensor && deviceSensor.sensor.sensorType &&deviceSensor.sensor && deviceSensor.sensor.sensorType.name}</td>
                    <td>{deviceSensor.sensor && deviceSensor.sensor.sensorType && deviceSensor.sensor.sensorType.jsonGroup.name}</td>
                    <td>{deviceSensor.deviceSensorStatus && deviceSensor.deviceSensorStatus.name}</td>
                </tr>
            });
            return <tr key={device.id}>
                <td style={{whiteSpace: 'nowrap'}}>{device.name}</td>
                <td>{device.macAddress}</td>
                <td>{device.deviceLocation && device.deviceLocation.name}</td>
                <td>{device.deviceLocation && device.deviceLocation.outlet}</td>
                <td>{device.deviceLocation && device.deviceLocation.street} {device.deviceLocation && device.deviceLocation.streetNumber}</td>
                <td>{device.deviceLocation && device.deviceLocation.city && device.deviceLocation.city.name}</td>
                <td>
                    <Table className="mt-4">
                    <thead>
                        <tr>
                            <th width="5%">Sensor ID</th>
                            <th width="10%">Place</th>
                            <th width="5%">PipeLine</th>
                            <th width="5%">Object</th>
                            <th width="5%">Quantity</th>
                            <th width="5%">Unit</th>
                            <th width="10%">Sensor</th>
                            <th width="10%">Sensor type</th>
                            <th width="10%">JSON group</th>
                            <th width="5%">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deviceSensorList}
                    </tbody>
                    </Table>
                </td>
                <td>
                    <ButtonGroup>
                        <Button size="sm" color="primary" disabled={!this.state.value} tag={Link} to={'/device/' + device.id}>Edit</Button>
                        <Button size="sm" color="danger" disabled={!this.state.value} onClick={() => this.remove(device.id)}>Delete</Button>
                    </ButtonGroup>
                </td>
            </tr>
        });

        return (
            <div>
                <AppNavbar/>
                <Container fluid>
                    <div className="float-right">
                        <Button color="success" disabled={!this.state.value} tag={Link} to={"/" + this.routePath + "/new"}>Add Device</Button>
                    </div>
                    <h3>Devices</h3>
                    <Table className="mt-4">
                        <thead>
                            <tr>
                                <th width="5%">Name</th>
                                <th width="5%">MacAddress</th>
                                <th width="5%">Location</th>
                                <th width="5%">Outlet</th>
                                <th width="5%">Street</th>
                                <th width="5%">City</th>
                                <th width="65%">Sensors</th>
                                <th width="5%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deviceList}
                        </tbody>
                    </Table>
                </Container>
            </div>
        );
    }
}
export default DeviceList;