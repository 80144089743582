import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import DeviceList from './model/DeviceList';
import DeviceEdit from './model/DeviceEdit';
import Home from './layout/AppNavbar';

class App extends Component {
  render() {
    return (
        <Router>
            <Routes>
                <Route path='/' exact={true} component={Home}/>
                <Route path='/devices' exact={true} element={<DeviceList/>}/>
                <Route path='/device/:id' element={<DeviceEdit/>}/>
            </Routes>
        </Router>
    )
  }
}

export default App;
